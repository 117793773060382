<template>
  <v-autocomplete
    ref="alcance"
    :required="required"
    :rules="rules"
    :dense="dense"
    :outlined="outlined"
    :disabled="disabled"
    :readonly="readonly"
    v-model="selected"
    :item-text="nombre"
    item-value="uuid"
    return-object
    label="Alcance"
    :clearable="clearable"
    :hide-details="hideDetails"
    :items="items"
    @change="emitChange()">
  </v-autocomplete>
</template>

<script>
export default {
  name: 'SelectAlcancesObject',
  props: {
    value: [String, Array, Object],
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    addAll: { type: Boolean, default: false },
    autoSelectFirst: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
  },
  mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Este campo es obligatorio']
    }
    this.getItems()
  },
  data(ctx) {
    return {
      rules: [],
      selected: ctx.value,
      items: [],
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.alcance.reset()
      this.selected = val
    },
  },
  methods: {
    nombre(item) {
      return item.nombre
      // return (item.codigo)
      //   ? `${item.codigo} - ${item.nombre}`
      //   : item.nombre;
    },

    //+-------------------------------------------------
    // autoSelectItems()
    // Selecciona los items de la lista que coincidan con el valor de autoSelectField
    // -----
    // Created on Wed Jun 29 2022
    //+-------------------------------------------------
    autoSelectItems() {
      if (this.value?.length > 0) return

      if (this.autoSelectFirst || this.items.length == 1) {
        this.selected = this.items[0]
        this.emitChange()
        return
      }
    },

    async getItems() {
      const res = await this.$store.dispatch('alcances/list')
      if (res) this.items = res

      if (this.addAll) this.items.push({ nombre: 'Todos los alcances', uuid: 'all' })
      // if (res) this.items = [{"nombre": 'TODOS', "uuid": "all"}].concat(res)

      if (res.length > 0) this.autoSelectItems()
      this.selected = this.items.find((item) => item.uuid === this.value.uuid)
      this.emitChange()
    },
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
  },
}
</script>

<style scoped></style>
