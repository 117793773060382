/**
 * @project: certiapp-nuxt
 * @file:    auditorias.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Jun 21 2023
 **/

import { uuid } from 'vue-uuid'
import moment from 'moment'
import estados from '~/models/estados'

export const state = () => ({
  list: [],
  previstas: [],
  offlineMode: false,
  offlineUuid: '',
  offlineActions: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_PREVISTAS(state, items) {
    state.previstas = items
  },
  OFFLINE_MODE(state, mode) {
    state.offlineMode = mode
  },
  OFFLINE_UUID(state, uuid) {
    state.offlineUuid = uuid
  },
  OFFLINE_ACTION(state, action) {
    const storage = JSON.parse(localStorage.getItem('offline_actions_auditoria'))
    if (storage) state.offlineActions = storage
    state.offlineActions.push(action)
    localStorage.setItem('offline_actions_auditoria', JSON.stringify(state.offlineActions))
  },
  CLEAR_OFFLINE_MODE(state) {
    state.offlineActions = []
    state.offlineMode = false
    state.offlineUuid = ''
    localStorage.removeItem('offline_actions_auditoria')
    localStorage.removeItem('auditoria')
    localStorage.removeItem('pacs')
    localStorage.removeItem('noConformidadesPendientesPac')
    localStorage.removeItem('offlineMode')
    localStorage.removeItem('offlineUuid')
  },
}

export const getters = {
  offlineMode(state) {
    const offlineMode = state.offlineMode
    const offlineUuid = state.offlineUuid
    const routeParams = offlineUuid === $nuxt.$router.currentRoute.params.uuid
    const routeQuery = offlineUuid === $nuxt.$router.currentRoute.query.auditoria_uuid
    return routeParams || routeQuery ? offlineMode : false
  },
}

//dispatch actions
export const actions = {
  async tipos_visita() {
    const res = await this.$axios.get('auditorias/tipos-visita')
    console.info('tipos_visita', res)
    if (res) return res.data
  },

  async goOnline({ rootState, state, commit, dispatch }) {
    rootState.loading = true
    const res = await this.$axios.get('/users/current')
    if (res.status === 200) {
      await commit('OFFLINE_MODE', false)
      await this.dispatch('offlineMode/goOnline')
      await this.dispatch('tomas_muestra/goOnline')
      const storage = JSON.parse(localStorage.getItem('offline_actions_auditoria'))
      if (storage && storage.length > 0) {
        for (const element of storage) {
          await dispatch(element.action, element.body)
        }
      }
      commit('CLEAR_OFFLINE_MODE')
    }
    rootState.loading = false
  },

  async list({ rootState, commit }, payload) {
    rootState.loading = true
    const body = {}
    if (payload && payload.expediente) body.expediente = payload.expediente
    if (payload && payload.alcance) body.alcance = payload.alcance
    if (payload && payload.cliente) body.cliente = payload.cliente
    if (payload && payload.estados) body.estados = payload.estados
    //serverside pagination
    if (payload && payload.limit) body.limit = payload.limit
    if (payload && payload.page) body.page = payload.page
    if (payload && payload.sortBy && payload.sortDesc)
      body.sort = { field: payload.sortBy[0], order: payload.sortDesc[0] ? 'desc' : 'asc' }
    const res = await this.$axios.put('auditorias', body)

    // TODO: 02/11/2021
    // check if used and delete
    let eventos = []
    rootState.loading = false
    if (res) {
      const data = res.data
      try {
        data.forEach((event) => {
          event.name = 'Cliente: ' + event.nombre_cliente
          event.start = moment(event.desde).format('YYYY-MM-DD')
          event.end = moment(event.hasta).format('YYYY-MM-DD')
          event.color = getColorAlcance(event)
          eventos.push(event)
        })
      } catch (error) {
      }
      commit('SET_LIST', eventos)
      return data
    }
  },

  async previstas({ rootState, commit }, payload) {
    rootState.loading = true
    const body = {}
    if (payload && payload.expediente) body.expediente = payload.expediente
    if (payload && payload.cliente) body.cliente = payload.cliente
    const res = await this.$axios.put('auditorias/previstas', body)
    let eventos = []
    rootState.loading = false
    if (res) {
      const data = res.data
      data.forEach((event) => {
        event.name = 'Cliente: ' + event.cliente.nombre
        event.start = moment(event.fecha_prevista).format('YYYY-MM-DD')
        event.end = moment(event.fecha_prevista).format('YYYY-MM-DD')
        // event.color = getColorAlcance(event)
        event.color = '#DD2B0E'
        eventos.push(event)
      })
      commit('SET_PREVISTAS', eventos)
      return data
    }
  },

  async get({ rootState, state, commit }, uuid) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('auditoria'))
    // Check Offline
    if (state.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.$axios.get(`auditorias/${uuid}`)

      // TODO: <listas-validacion> Establecimiento de datos para obtener las listas de validación */
      // todo: lanzar evento de cambio de alcance.
      rootState.listas_validacion.ref_uuid = res.data.uuid
      if (res.data.alcance && res.data.alcance.uuid)
        rootState.listas_validacion.alcance = res.data.alcance.uuid
      /* </listas-validacion> */

      rootState.loading = false
      if (res) {
        if (state.offlineMode) localStorage.setItem('auditoria', JSON.stringify(res.data))
        return res.data
      }
    }
  },

  // #########################
  // PLANIFICADOR (CALENDARIO)
  // #########################

  async create({ rootState }, auditoria) {
    rootState.loading = true
    const body = {
      ...auditoria,
      uuid: uuid.v4(),
    }
    console.log('create -> body', body)
    // const body = {
    //   uuid: uuid.v4(),
    //   titulo: auditoria.titulo,
    //   alcance: auditoria.alcance,
    //   descripcion: auditoria.descripcion,
    //   cliente: auditoria.cliente,
    //   bloqueada: auditoria.bloqueada,
    //   auditores: auditoria.auditores,
    //   desde: auditoria.desde,
    //   hasta: auditoria.hasta,
    //   sites: auditoria.sites,
    //   tipo: auditoria.tipo.uuid,
    //   iteracion: auditoria.iteracion,
    //   responsable: auditoria.responsable.uuid,
    //   notas_planificacion: auditoria.notas_planificacion
    // }
    await this.$axios.post('auditorias', body)
    rootState.loading = false
  },

  async update({ rootState, state, commit }, auditoria) {
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'update',
        body: auditoria,
      }
      commit('OFFLINE_ACTION', action)
      localStorage.setItem('auditoria', JSON.stringify(auditoria))
    } else {
      const auditores = auditoria.auditores.map((auditor) => auditor.uuid)
      const responsable = auditoria.responsable.uuid
      const revisor = auditoria?.revisor?.uuid || auditoria.revisor
      if (revisor) {
        if (!auditores.includes(revisor))
        auditores.push(revisor)
      }
      const alcance = auditoria.alcance.uuid
      const body = {
        titulo: '',
        alcance: alcance,
        codigo: auditoria.codigo,
        estado: auditoria.estado,
        descripcion: auditoria.descripcion,
        cliente: auditoria.cliente.uuid,
        auditoria: auditoria.uuid,
        bloqueada: auditoria.bloqueada,
        auditores: auditores,
        cargos: auditoria.cargos,
        fechas: auditoria.fechas,
        desde: auditoria.desde,
        desde_inicio: auditoria.desde_inicio,
        desde_fin: auditoria.desde_fin,
        hasta: auditoria.hasta,
        hasta_inicio: auditoria.hasta_inicio,
        hasta_fin: auditoria.hasta_fin,
        tipo_visita: auditoria.tipo_visita,
        nivel: auditoria.nivel,
        iteracion: auditoria.iteracion.uuid,
        laboratorio: auditoria.laboratorio,
        anotaciones: auditoria.anotaciones,
        responsable: responsable,
        representante: auditoria.representante,
        representante_en_auditoria: auditoria.representante_en_auditoria,
        revisor: revisor,
        notas_planificacion: auditoria.notas_planificacion,
        actividades_realizadas: auditoria.actividades_realizadas,
        esquema_auditado: auditoria.esquema_auditado,

        fecha_informe: auditoria.fecha_informe,

        acompanante_1: auditoria.acompanante_1,
        acompanante_2: auditoria.acompanante_2,
        cargo_acompanante_1: auditoria.cargo_acompanante_1,
        cargo_acompanante_2: auditoria.cargo_acompanante_2,

        valoracion: auditoria.valoracion,
      }

      // if (body.desde_inicio == '') body.desde_inicio = null
      // if (body.hasta_inicio == '') body.hasta_inicio = null
      // if (body.desde_fin == '') body.desde_fin = null
      // if (body.hasta_fin == '') body.hasta_fin = null

      if (auditoria.sites) {
        body.sites = prepareSites(auditoria.sites)
      }

      await this.$axios.patch(`auditorias/${auditoria.uuid}`, body)
    }
  },

  //+-------------------------------------------------
  // planificar()
  // -----
  // Created on Tue May 04 2021
  //+-------------------------------------------------
  async planificar({ rootState }, auditoria) {
    rootState.loading = true

    const auditores = auditoria.auditores.map((auditor) => auditor.uuid)
    const responsable = auditoria.responsable.uuid
    const alcance = auditoria.alcance.uuid
    const body = {
      titulo: '',
      alcance: alcance,
      codigo: auditoria.codigo,
      estado: auditoria.estado,
      descripcion: auditoria.descripcion,
      cliente: auditoria.cliente.uuid,
      auditoria: auditoria.uuid,
      bloqueada: auditoria.bloqueada,
      auditores: auditores,
      desde: auditoria.desde,
      hasta: auditoria.hasta,
      tipo_visita: auditoria.tipo_visita,
      nivel: auditoria.nivel,
      iteracion: auditoria.iteracion.uuid,
      anotaciones: auditoria.anotaciones,
      responsable: responsable,
      notas_planificacion: auditoria.notas_planificacion,
      esquema_auditado: auditoria.esquema_auditado,
    }

    if (auditoria.sites) {
      body.sites = prepareSites(auditoria.sites)
    }

    await this.$axios.patch(`auditorias/${auditoria.uuid}/planificar`, body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // confirmar()
  // Cambia el estado a confirmada
  // -----
  // Created on Thu Oct 21 2021
  //+-------------------------------------------------
  async confirmar({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`auditorias/${params.uuid}/confirmar`, {
      auditoria: params.uuid,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Upddated on Tue Jul 20 2021
  //+-------------------------------------------------
  async delete({ rootState }, auditoria) {
    console.log('delete', auditoria)
    rootState.loading = true
    const res = await this.$axios.delete(`auditorias/${auditoria.uuid}`)
    rootState.loading = false
    return res
  },
  // ######################################
  // EDICIÓN DE AUDITORÍA - AMPLIACIONES
  // ######################################

  async ampliacion({ rootState }, params) {
    // rootState.loading = true
    const body = {
      ...params,
    }
    await this.$axios.patch(`auditorias/${params.auditoria}/ampliacion`, body)
    // rootState.loading = false
  },
  // ######################################
  // EDICIÓN DE AUDITORÍA - PLANIFICACIONES
  // ######################################

  async addPlanificacion({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'addPlanificacion',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('auditoria'))
      storage.planificaciones.push(data.planificacion)
      localStorage.setItem('auditoria', JSON.stringify(storage))

      rootState.loading = false
      return storage
    } else {
      const planificacion = data.planificacion
      console.info('plan', planificacion)
      const res = await this.$axios.patch(`auditorias/${data.auditoria}/add-planificacion`, {
        auditoria: data.auditoria,
        auditor: planificacion.auditor.uuid,
        plantillas_checklist: [planificacion.plantilla_checklist.uuid],
        sites: [planificacion.site.uuid],
        certificado: planificacion.certificado,
      })
      rootState.loading = false
      if (res) return res.data
    }
  },

  async updateSiteCertificado({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'updateSiteCertificado',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('auditoria'))
      const index = storage.sites.findIndex((e) => e.uuid == data.site)
      storage.sites[index].certificado = data.certificado

      rootState.loading = false
      return storage
    } else {
      const body = {
        site: data.site,
        certificado: data.certificado,
      }
      await this.$axios.patch(
        `auditorias/${data.auditoria}/planificaciones/${data.site}/update-planificacion-certificado`,
        body
      )
      rootState.loading = false
    }
  },

  async removePlanificacion({ rootState, state, commit }, data) {
    // rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'removePlanificacion',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('auditoria'))
      const index = storage.planificaciones.indexOf(data.planificacion)
      storage.planificaciones.splice(index, 1)
      localStorage.setItem('auditoria', JSON.stringify(storage))
    } else {
      await this.$axios.delete(`auditorias/${data.auditoria}/planificaciones/${data.planificacion}`)
    }
    // rootState.loading = false
  },

  async cambiarEstado({ rootState }, auditoria) {
    rootState.loading = true
    //console.log('[cambiarEstado]', auditoria)
    if (auditoria.estado === estados.AUDITORIA_ESTADO.CERRADA) {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/cerrar`, { auditoria: auditoria.uuid })
    } else if (auditoria.estado === estados.AUDITORIA_ESTADO.ABIERTA) {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/abrir`, { auditoria: auditoria.uuid })
    } else if (auditoria.estado === estados.AUDITORIA_ESTADO.REVISADA_OK) {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/revisada`, {
        auditoria: auditoria.uuid,
      })
    } else if (auditoria.estado === estados.AUDITORIA_ESTADO.APTA) {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/apta`, { auditoria: auditoria.uuid })
    } else if (auditoria.estado === estados.AUDITORIA_ESTADO.NO_APTA) {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/no_apta`, { auditoria: auditoria.uuid })
    } else if (auditoria.estado === 'TERMINADA') {
      await this.$axios.patch(`auditorias/${auditoria.uuid}/terminar`, {
        auditoria: auditoria.uuid,
      })
    }
    rootState.loading = false
  },

  // ###########################
  // EDICIÓN DE AUDITORÍA - PACS
  // ###########################

  async pacs({ rootState, state, dispatch }, auditoria) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('pacs'))
    // Check Offline
    if (state.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.$axios.get(`auditorias/${auditoria}/pacs`)
      if (res) {
        const data = res.data
        const pacs = []
        const documentos_custom = []
        if (state.offlineMode) {
          // Llamamos el get de cada pac
          for (const element of data) {
            let body = {
              auditoria: auditoria,
              pac: element.uuid,
            }
            let pac = await dispatch('pac', body)
            if (pac) {
              let acciones_correctivas = await dispatch('getAccionesCorrectivasPAC', body)
              if (acciones_correctivas) {
                pac.acciones_correctivas = acciones_correctivas
                for (let i = 0; i < pac.acciones_correctivas.length; i++) {
                  const accion_correctiva = pac.acciones_correctivas[i]
                  let payload = {
                    modulo: 'auditorias',
                    modulo_ref: auditoria,
                    submodulo: 'acciones_correctivas',
                    submodulo_ref: accion_correctiva.uuid,
                  }
                  let documentos = await this.dispatch('gestor_documental/listCustom', payload)
                  documentos.forEach((doc) => documentos_custom.push(doc))
                }
              }
              pacs.push(pac)
            }
          }
          localStorage.setItem('pacs', JSON.stringify(pacs))
          localStorage.setItem('documentos_custom', JSON.stringify(documentos_custom))
        }
        rootState.loading = false
        return pacs.length > 0 ? pacs : data
      }
    }
  },

  async pac({ rootState, state, commit }, payload) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('pacs'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      let pac = {}
      storage.forEach((element) => {
        if (element.uuid === payload.pac) pac = element
      })
      rootState.loading = false
      return pac
    } else {
      const res = await this.$axios.get(`auditorias/${payload.auditoria}/pacs/${payload.pac}`)
      rootState.loading = false
      if (res) return res.data
    }
  },

  async noConformidadesPendientesPac({ rootState, state, commit }, auditoria) {
    let storage = JSON.parse(localStorage.getItem('noConformidadesPendientesPac'))
    // Check Offline
    if (state.offlineMode && storage) {
      // Checklists
      const checklists = JSON.parse(localStorage.getItem('auditoria_checklist'))
      if (checklists) {
        // PACS
        const pacs = JSON.parse(localStorage.getItem('pacs'))
        let no_conformidades_pacs = []
        if (pacs) {
          for (let i = 0; i < pacs.length; i++) {
            const element = pacs[i]
            no_conformidades_pacs = no_conformidades_pacs.concat(element.no_conformidades)
          }
        }

        //Preguntas - No Conformidades
        const no_conformidades_pendientes = []
        let preguntas = []

        for (let i = 0; i < checklists.length; i++) {
          const element = checklists[i]
          preguntas = preguntas.concat(element.preguntas)
        }

        for (let i = 0; i < preguntas.length; i++) {
          const pregunta = preguntas[i]
          const isNoConformidad = pregunta.respuesta === 'F'
          const isNotPendiente = !no_conformidades_pendientes.some((e) => e.uuid === pregunta.uuid)
          const isNotPAC = !no_conformidades_pacs.some((e) => e.uuid === pregunta.uuid)
          if (isNoConformidad && isNotPendiente && isNotPAC) {
            no_conformidades_pendientes.push(pregunta)
          }
        }

        storage = no_conformidades_pendientes
        localStorage.setItem('noConformidadesPendientesPac', JSON.stringify(storage))
      }
      return storage
    } else {
      rootState.loading = true
      const res = await this.$axios.get(`auditorias/${auditoria}/pacs/no-conformidades-pendientes`)
      rootState.loading = false
      if (res) {
        if (state.offlineMode)
          localStorage.setItem('noConformidadesPendientesPac', JSON.stringify(res.data))
        return res.data
      }
    }
  },

  async crearPac({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      data.uuid = uuid.v4()
      const action = {
        action: 'crearPac',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const pacs = JSON.parse(localStorage.getItem('pacs'))
      const noConformidadesPendientesPac = JSON.parse(
        localStorage.getItem('noConformidadesPendientesPac')
      )
      data.created_at = new Date()
      pacs.push(data)
      if (data.no_conformidades && data.no_conformidades.length > 0) {
        data.no_conformidades.forEach((element) => {
          let item = {}
          noConformidadesPendientesPac.forEach((el) => {
            if (element.uuid === el.uuid) item = el
          })
          let index = noConformidadesPendientesPac.indexOf(item)
          noConformidadesPendientesPac.splice(index, 1)
        })
      }
      localStorage.setItem('pacs', JSON.stringify(pacs))
      localStorage.setItem(
        'noConformidadesPendientesPac',
        JSON.stringify(noConformidadesPendientesPac)
      )
    } else {
      let new_pac_uuid = data.uuid ? data.uuid : uuid.v4()
      await this.$axios.post(`auditorias/${data.auditoria}/pacs`, {
        uuid: new_pac_uuid,
        auditoria: data.auditoria,
      })

      // Añadimos las no conformidades seleccionadas
      for (let index = 0; index < data.no_conformidades.length; index++) {
        const item = data.no_conformidades[index]
        await this.$axios.post(
          `auditorias/${data.auditoria}/pacs/${new_pac_uuid}/add-no-conformidad`,
          {
            no_conformidad: item.uuid,
            pac: new_pac_uuid,
          }
        )
      }
    }
    rootState.loading = false
  },

  async savePac({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'savePac',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('pacs'))
      storage.forEach((element) => {
        if (element.uuid === data.uuid) element = data
      })
      localStorage.setItem('pacs', JSON.stringify(storage))
    } else {
      await this.$axios.patch(`auditorias/${data.auditoria}/pacs/${data.pac}`, {
        pac: data.pac,
        auditoria: data.auditoria,
        fecha_limite: data.fecha_limite,
        fecha_validacion: data.fecha_validacion,
        created_at: data.created_at,
      })
    }
    rootState.loading = false
  },

  async deletePac({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'deletePac',
        body: data,
      }
      commit('OFFLINE_ACTION', action)
      const noConformidadesPendientesPac = JSON.parse(
        localStorage.getItem('noConformidadesPendientesPac')
      )
      const pacs = JSON.parse(localStorage.getItem('pacs'))
      let item = {}
      pacs.forEach((element) => {
        if (element.uuid === data.pac) item = element
      })
      const index = pacs.indexOf(item)
      item.no_conformidades.forEach((element) => {
        noConformidadesPendientesPac.push(element)
      })
      pacs.splice(index, 1)
      localStorage.setItem('pacs', JSON.stringify(pacs))
      localStorage.setItem(
        'noConformidadesPendientesPac',
        JSON.stringify(noConformidadesPendientesPac)
      )
    } else {
      await this.$axios.delete(`auditorias/${data.auditoria}/pacs/${data.pac}`)
    }
    rootState.loading = false
  },

  async addNoConformidad({ rootState }, params) {
    const body = {
      no_conformidad: params.no_conformidad,
      pac: params.pac_uuid,
    }
    console.log('[addNoConformidad]', body)
    // const {data} = await this.$axios.post(`auditorias/${params.auditoria_uuid}/pacs/${params.pac_uuid}/add-no-conformidad`, body);
    return data
  },

  async removeNoConformidad({ rootState }, params) {
    await this.$axios.delete(
      `auditorias/${params.auditoria_uuid}/pacs/${params.pac_uuid}/remove-no-conformidad/${params.no_conformidad}`
    )
  },

  // ###########################
  // PACS - ACCIONES CORRECTIVAS
  // ###########################

  async getAccionesCorrectivasPAC({ rootState, state }, params) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('pacs'))
    // Check Offline
    if (state.offlineMode && storage) {
      rootState.loading = false
      let acciones_correctivas = []
      storage.forEach((element) => {
        if (element.uuid === params.pac) acciones_correctivas = element.acciones_correctivas
      })
      return acciones_correctivas
    } else {
      const res = await this.$axios.get(
        `auditorias/${params.auditoria}/pacs/${params.pac}/acciones-correctivas`
      )
      rootState.loading = false
      if (res) return res.data
    }
  },

  async crearAccionCorrectiva({ rootState, state, commit }, data) {
    rootState.loading = true

    let no_conformidades = []
    if (state.offlineMode)
      data.corrige_no_conformidades.forEach((no_conformidad) =>
        no_conformidades.push(no_conformidad)
      )
    else
      data.corrige_no_conformidades.forEach((no_conformidad) =>
        no_conformidades.push(no_conformidad.uuid)
      )

    let payload = {
      uuid: data.uuid,
      pac: data.pac,
      tipo: data.tipo,
      causa: data.causa,
      accion_propuesta: data.accion_propuesta,
      evidencias_aportadas: data.evidencias_aportadas,
      valoracion_causa: data.valoracion_causa,
      valoracion_accion: data.valoracion_accion,
      valoracion_evidencias: data.valoracion_evidencias,
      fecha_implantacion: data.fecha_implantacion,
      fecha_valoracion_causa: data.fecha_valoracion_causa,
      fecha_valoracion_accion: data.fecha_valoracion_accion,
      fecha_valoracion_evidencias: data.fecha_valoracion_evidencias,
      responsable_implantacion: data.responsable_implantacion,
      no_conformidades: no_conformidades,
      verificacion_realizada_autor: data.verificacion_realizada_autor,
      accion_inmediata: data.accion_inmediata,
      valoracion_accion_inmediata: data.valoracion_accion_inmediata,
      fecha_accion_correctora: data.fecha_valoracion_accion,
      plazo_accion_correctora: data.plazo_accion_correctora,
      evaluacion: data.evaluacion,
      cierre: data.cierre,
      fecha_cierre: data.fecha_cierre,
      seguimiento_cierre: data.seguimiento_cierre,
      comentarios: data.comentarios,
    }

    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'crearAccionCorrectiva',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('pacs'))
      storage.forEach((element) => {
        if (element.uuid === data.pac) {
          if (element.acciones_correctivas && element.acciones_correctivas.length > 0)
            element.acciones_correctivas.push(payload)
          else {
            element.acciones_correctivas = []
            element.acciones_correctivas.push(payload)
          }
        }
      })
      localStorage.setItem('pacs', JSON.stringify(storage))
    } else {
      await this.$axios.post(
        `auditorias/${data.auditoria}/pacs/${data.pac}/acciones-correctivas`,
        payload
      )
    }
    rootState.loading = false
  },

  async updateAccionCorrectiva({ rootState, state, commit }, data) {
    rootState.loading = true

    let no_conformidades = []
    if (state.offlineMode)
      data.corrige_no_conformidades.forEach((no_conformidad) =>
        no_conformidades.push(no_conformidad)
      )
    else
      data.corrige_no_conformidades.forEach((no_conformidad) =>
        no_conformidades.push(no_conformidad.uuid)
      )

    let payload = {
      accion_correctiva: data.uuid,
      pac: data.pac,
      tipo: data.tipo,
      causa: data.causa,
      accion_propuesta: data.accion_propuesta,
      evidencias_aportadas: data.evidencias_aportadas,
      valoracion_causa: data.valoracion_causa,
      valoracion_accion: data.valoracion_accion,
      valoracion_evidencias: data.valoracion_evidencias,
      fecha_implantacion: data.fecha_implantacion,
      fecha_valoracion_causa: data.fecha_valoracion_causa,
      fecha_valoracion_accion: data.fecha_valoracion_accion,
      fecha_valoracion_evidencias: data.fecha_valoracion_evidencias,
      responsable_implantacion: data.responsable_implantacion,
      no_conformidades: no_conformidades,
      verificacion_realizada_autor: data.verificacion_realizada_autor,
      accion_inmediata: data.accion_inmediata,
      valoracion_accion_inmediata: data.valoracion_accion_inmediata,
      fecha_accion_correctora: data.fecha_valoracion_accion,
      plazo_accion_correctora: data.plazo_accion_correctora,
      evaluacion: data.evaluacion,
      cierre: data.cierre,
      fecha_cierre: data.fecha_cierre,
      seguimiento_cierre: data.seguimiento_cierre,
      comentarios: data.comentarios,
    }

    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'updateAccionCorrectiva',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('pacs'))
      let pacIndex = null
      let accionIndex = null
      payload.uuid = data.uuid
      for (let i = 0; i < storage.length; i++) {
        const element = storage[i]
        if (element.uuid === data.pac) {
          pacIndex = i
          for (let n = 0; n < element.acciones_correctivas.length; n++) {
            const accion = element.acciones_correctivas[n]
            if (accion.uuid === data.uuid) accionIndex = n
          }
        }
      }
      storage[pacIndex].acciones_correctivas.splice(accionIndex, 1, payload)
      localStorage.setItem('pacs', JSON.stringify(storage))
    } else {
      await this.$axios.patch(
        `auditorias/${data.auditoria}/pacs/${data.pac}/acciones-correctivas/${data.uuid}`,
        payload
      )
    }
    rootState.loading = false
  },

  async deleteAccionCorrectiva({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'deleteAccionCorrectiva',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('pacs'))
      storage.forEach((element) => {
        if (element.uuid === data.pac) {
          const index = element.acciones_correctivas.indexOf(data)
          element.acciones_correctivas.splice(index, 1)
        }
      })
      localStorage.setItem('pacs', JSON.stringify(storage))
    } else {
      await this.$axios.delete(
        `auditorias/${data.auditoria}/pacs/${data.pac}/acciones-correctivas/${data.uuid}`
      )
    }
    rootState.loading = false
  },

  // ##################################
  // AUDITORÍA CHECKLIST
  // ##################################
  async replaceSiteChecklist({ rootState, state, commit }, data) {
    rootState.loading = true

    await this.$axios.patch(
      `auditorias/${data.auditoria}/checklists/${data.checklist}/update-site`,
      data
    )

    rootState.loading = false
  },

  async saveChecklist({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'saveChecklist',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('auditoria_checklist'))
      storage.forEach((element) => {
        if (element.uuid === data.checklist) {
          if (data.fecha) element.fecha = data.fecha
          if (data.inicio) element.inicio = data.inicio
          if (data.fin) element.fin = data.fin
        }
      })
      localStorage.setItem('auditoria_checklist', JSON.stringify(storage))
    } else {
      await this.$axios.patch(`auditorias/${data.auditoria}/checklists/${data.checklist}`, data)
    }
    rootState.loading = false
  },

  async cumplimientoChecklist({ state }, preguntas) {
    let cumplimiento = { mayores: 0, menores: 0, completados: 100 }
    try {
      if (!preguntas || preguntas.length === 0) return cumplimiento
      else {
        const preguntas_cumplimentables = preguntas.filter((p) => {
          return !p.solo_titulo
        })

        let num_preguntas = preguntas_cumplimentables.length

        let num_mayores = 0
        let num_menores = 0
        let num_superiores = 0
        let num_completados = 0
        if (num_preguntas === 0) return cumplimiento
        for (let index = 0; index < num_preguntas; index++) {
          const pregunta = preguntas_cumplimentables[index]

          // Nota 26/01/2022
          // Los pesos deberian venir de los estaticos
          if (pregunta.peso === 'Mayor' || pregunta.peso === 'Grave') num_mayores++
          if (pregunta.peso === 'Menor' || pregunta.peso === 'Leve') num_menores++
          if (pregunta.peso === 'Muy Grave') num_superiores++

          if (pregunta.respuesta) num_completados++

          if (index === num_preguntas - 1) {
            cumplimiento.mayores = Math.trunc((num_mayores / num_preguntas) * 100)
            cumplimiento.menores = Math.trunc((num_menores / num_preguntas) * 100)
            cumplimiento.superiores = Math.trunc((num_superiores / num_preguntas) * 100)
            cumplimiento.completados = Math.trunc((num_completados / num_preguntas) * 100)
            return cumplimiento
          }
        }
      }
    } catch (error) {
      console.log('[ERROR] store auditorias - cumplimientoChecklist()', error)
      return cumplimiento
    }
  },

  // ##################################
  // EDICIÓN DE PREGUNTAS DEL CHECKLIST
  // ##################################
  async editRespuesta({ rootState, state, commit, dispatch }, params) {
    console.log('[STORE] editRespuesta', params)
    if (state.offlineMode) {
      const action = {
        action: 'editRespuesta',
        body: params,
      }
      commit('OFFLINE_ACTION', action)
      const storage = JSON.parse(localStorage.getItem('auditoria_checklist'))
      storage.forEach((element) => {
        if (element.uuid === params.checklist_uuid) {
          element.preguntas.forEach((item) => {
            if (item.uuid === params.checklist_pregunta_uuid) {
              if (params.observaciones) item.observaciones = params.observaciones
              if (params.no_conformidad) item.no_conformidad = params.no_conformidad
              if (params.respuesta) item.respuesta = params.respuesta
              if (params.peso) item.peso = params.peso
              if (params.numero) item.numero = params.numero
              if (params.comentarios) item.comentarios = params.comentarios
              if (params.requisito_afectado) item.requisito_afectado = params.requisito_afectado
            }
          })
        }
      })
      localStorage.setItem('auditoria_checklist', JSON.stringify(storage))
      dispatch('noConformidadesPendientesPac')
    } else {
      const body = {
        checklist_pregunta: params.checklist_pregunta_uuid,
        observaciones: params.observaciones,
        no_conformidad: params.no_conformidad,
        respuesta: params.respuesta,
        peso: params.peso,
        numero: params.numero,
        tipologia: params.tipologia,
        instalacion: params.instalacion,
        comentarios: params.comentarios,
        requisito_afectado: params.requisito_afectado,
      }
      console.log('[STORE] editRespuesta', body)
      const res = await this.$axios.patch(
        `auditorias/${params.auditoria_uuid}/checklists/${params.checklist_uuid}/preguntas/${params.checklist_pregunta_uuid}`,
        body
      )
      if (res) return res.data
    }
  },

  //+-------------------------------------------------
  // toggleLock()
  // Lock the auditoria
  // -----
  // Created on Wed Nov 10 2021
  //+-------------------------------------------------
  async toggleLock({ rootState }, params) {
    rootState.loading = true

    let action = params.action == 'lock' ? 'bloquear' : 'desbloquear'
    const res = await this.$axios.patch(`auditorias/${params.uuid}/${action}`, {
      auditoria: params.uuid,
    })

    rootState.loading = false
    if (res) return res.data
  },
}

/**
 * Función para obtener el color por Alcande de un evento
 * @param {*} event
 */
function getColorAlcance(event) {
  try {
    if (event.alcance.nombre === 'Globalgap') return 'light-blue'
    else if (event.alcance.nombre === 'Grasp') return 'green'
    else if (event.alcance.nombre === 'Nurture') return 'orange'
    else return 'cyan'
  } catch (error) {
    return 'light-blue'
  }
}

function prepareSites(theSites) {
  const tipos = theSites.tipos
  let sites = []

  if (tipos && tipos.length) {
    for (let i = 0; i < tipos.length; i++) {
      const tipo = tipos[i]
      const selection = theSites[tipo]
      if (selection) {
        let data = []
        if (selection.length) data = selection.map((element) => element.uuid)
        sites = sites.concat(data)
      }
    }
    // body.sites = sites
  }

  return sites
}
