/**
 * @project: certiapp-nuxt
 * @file:    \store\auditores.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Jul 19 2022
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true
    const res = await this.$axios.get('auditores')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`auditores/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // A new way to format the payload to store the client
  // user, and contact in the same call.
  // -----
  // Created on Tue Jun 08 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let data = { ...params }
    data.uuid = params.uuid || uuid.v4()
    if (data.pais?.uuid) data.pais = data.pais.uuid
    if (data.provincia?.uuid) data.provincia = data.provincia.uuid
    if (data.municipio?.uuid) data.municipio = data.municipio.uuid
    if (data.comunidad?.uuid) data.comunidad = data.comunidad.uuid
    if (data.localidad?.uuid) data.localidad = data.localidad.uuid

    await this.$axios.post('auditores', data)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Jun 11 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let data = { ...params }

    if (data.pais?.uuid) data.pais = data.pais.uuid
    if (data.provincia?.uuid) data.provincia = data.provincia.uuid
    if (data.municipio?.uuid) data.municipio = data.municipio.uuid
    if (data.comunidad?.uuid) data.comunidad = data.comunidad.uuid

    await this.$axios.patch(`auditores/${params.uuid}`, data)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // changeStatus()
  // Updates the status (alta, baja) for a client
  // -----
  // Created on Wed Jun 16 2021
  //+-------------------------------------------------
  async changeStatus({ rootState }, params) {
    rootState.loading = true

    let endpoint = params.status == 'alta' ? 'alta' : 'baja'
    let key = 'fecha_' + params.status
    let body = {
      auditor: params.uuid,
      observaciones: params.observaciones,
    }

    body[key] = params.fecha_valor
    await this.$axios.post(`auditores/${endpoint}`, body)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // getFirma()
  // -----
  // Created on Tue Jun 29 2021
  //+-------------------------------------------------
  async getFirma({ rootState }, params) {
    rootState.loading = true

    this.$axios({
      url: `auditores/${params.uuid}/firma`,
      method: 'get',
      responseType: 'blob',
    })
      .then((xhr) => {
        const blob = new Blob([xhr.data], { type: xhr.data.type })
        const url = window.URL.createObjectURL(blob)
        const img = document.createElement('img')
        const contentDisposition = getParameterCaseInsensitive(xhr.headers, 'Content-Disposition')

        let fileName = 'download' //href.substring(href.lastIndexOf("/") + 1)
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
        }

        img.src = url
        document.getElementById(params.imgid).src = url

        // document.body.appendChild(img)
        // window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        console.warn(e)
      })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // replaceFirma()
  // -----
  // Created on Wed Jun 16 2021
  //+-------------------------------------------------
  async replaceFirma({ rootState }, params) {
    rootState.loading = true

    let data = new FormData()
    data.append('auditor', params.uuid)
    data.append('file', params.file)

    let url = params.type == 'sello' ? 'auditor-firma-sello' : 'auditor-firma'
    const res = await this.$axios.post(`uploads/${url}`, data)

    rootState.loading = false
    if (res) return res.data
  },

  async changePassword({ rootState }, auditor) {
    rootState.loading = true
    let body = {
      auditor: auditor.uuid,
      password: auditor.user.password,
    }
    await this.$axios.patch(`auditores/${auditor.uuid}/change-password`, body)
    rootState.loading = false
  },

  async changeRoles({ rootState }, params) {
    rootState.loading = true
    let body = {
      auditor: params.uuid,
      roles: params.roles,
    }
    await this.$axios.patch(`auditores/${params.uuid}/change-roles`, body)
    rootState.loading = false
  },

  async delete({ rootState }, auditor) {
    rootState.loading = true
    await this.$axios.delete(`auditores/${auditor.uuid}`)
    rootState.loading = false
  },

  async getCompetencias({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`auditores/${uuid}/afinidades`)
    rootState.loading = false
    if (res) return res.data
  },

  async createCompetencia({ rootState }, params) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      ...params,
    }
    body.alcance = params.alcance.uuid
    if (params.subalcance && params.subalcance.uuid) body.subalcance = params.subalcance.uuid
    if (body.tramites) body.tramites = body.tramites.map((element) => element.uuid)
    await this.$axios.post(`auditores/${params.auditor}/afinidades`, body)
    rootState.loading = false
  },

  async updateCompetencia({ rootState }, params) {
    rootState.loading = true
    const body = {
      ...params,
    }
    body.afinidad = params.uuid
    body.alcance = params.alcance.uuid
    if (params.subalcance && params.subalcance.uuid) body.subalcance = params.subalcance.uuid
    if (body.tramites) body.tramites = body.tramites.map((element) => element.uuid)
    await this.$axios.patch(`auditores/${params.auditor}/afinidades/${params.uuid}`, body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // deleteCompetencia()
  // Delete a linked competetencia from the auditor
  // -----
  // Created on Wed Jul 21 2021
  //+-------------------------------------------------
  async deleteCompetencia({ rootState }, params) {
    rootState.loading = true
    await this.$axios.delete(`auditores/${params.auditor}/afinidades/${params.uuid}`)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // deleteIncompatibilidad()
  // Delete a linked competetencia from the auditor
  // -----
  // Created on Wed Jul 21 2021
  //+-------------------------------------------------
  async deleteIncompatibilidad({ rootState }, params) {
    rootState.loading = true
    await this.$axios.delete(`auditores/${params.auditor}/incompatibilidades/${params.uuid}`)
    rootState.loading = false
  },

  async getIncompatibilidades({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`auditores/${uuid}/incompatibilidades`)
    rootState.loading = false
    if (res) return res.data
  },

  async createIncompatibilidad({ rootState }, params) {
    rootState.loading = true
    const body = {
      ...params,
    }
    body.cliente = params.cliente.uuid
    body.alcance = params.alcance.uuid
    if (body.tramites) body.tramites = body.tramites.map((element) => element.uuid)
    await this.$axios.post(`auditores/${params.auditor}/incompatibilidades`, body)
    rootState.loading = false
  },

  async updateIncompatibilidad({ rootState }, params) {
    rootState.loading = true
    const body = {
      ...params,
    }
    body.incompatibilidad = params.uuid
    body.cliente = params.cliente.uuid
    body.alcance = params.alcance.uuid
    if (body.tramites) body.tramites = body.tramites.map((element) => element.uuid)
    await this.$axios.patch(`auditores/${params.auditor}/incompatibilidades/${params.uuid}`, body)
    rootState.loading = false
  },

  async checkUsername({ rootState }, username) {
    rootState.loading = true
    const res = await this.$axios.put(`auditores/check-username`, {
      username: username,
    })
    rootState.loading = false
    if (res) return res.data
  },

  async checkEmail({ rootState }, email) {
    rootState.loading = true
    const res = await this.$axios.put(`auditores/check-email`, {
      email: email,
    })
    rootState.loading = false
    if (res) return res.data
  },

  async changeImagen({ rootState }, auditor) {
    rootState.loading = true
    let formData = new FormData()
    formData.append('auditor', auditor.uuid)
    formData.append('file', auditor.file)
    await this.$axios.post(`uploads/auditor-avatar`, formData)
    rootState.loading = false
  },
}

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}
