<template>
  <v-select
    ref="tiposModificacionAlcance"
    :label="title"
    v-model="selected"
    :items="customItems.length ? customItems : solicitudTipos"
    :rules="rules"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    dense
    @change="changeTipoSolicitud"
  ></v-select>
</template>

<script>
import { solicitudTipos } from '../../util/constants'

export default {
  name: 'SelectTipoSolicitud',
  props: {
    value: String,
    title: { type: String, default: 'Tipo de solicitud' },
    required: { type: Boolean, default: true },
    isNew: { type: Boolean, default: false },
    disabled: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    alcance: { type: Object, default: {} },
    cliente: { type: Object, default: {} },
    customItems: { tpe: Array, default: () => [] },
    hideDetails: { type: String, default: 'auto' },
  },
  mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Obligatorio']
    }
  },
  data(ctx) {
    return {
      solicitudTipos,
      rules: [],
      selected: ctx.value,
      items: [],
      ultimaIteracionFetched: false,
    }
  },
  computed: {
    isQuesoManchego() {
      return this.alcance && this.alcance.codigo === 'queso-manchego'
    },
  },
  methods: {
    async getUltimaIteracion() {
      let body = {}
      if (this.cliente) body.cliente = this.cliente.uuid
      if (this.alcance) body.alcance = this.alcance.uuid
      const res = await this.$store.dispatch('expedientes/list', body)

      if (res.length === 0) {
        return
      }

      const xhr = await this.$store.dispatch('expedientes/iteracion', [
        res[0].uuid,
        res[0].lastIteracion_uuid,
      ])
      if (xhr) {
        this.$emit('modificacion', xhr)
        if(!this.isQuesoManchego)this.ultimaIteracionFetched = true
      }
    },

    changeTipoSolicitud(event) {
      this.ultimaIteracionFetched = false
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
      // if (this.solicitudTipos[1].value === event && !this.ultimaIteracionFetched) {

      if (!this.ultimaIteracionFetched) {
        if (event == 'modificacion' || event == 'renovacion' || event == 'suspension' || event == 'finalizacion') {
          this.getUltimaIteracion()
        }
      }
    },
  },
  watch: {
    value: function (val) {
      if (!val) this.$refs.tiposModificacionAlcance.reset()
      this.selected = val
    },
  },
}
</script>
